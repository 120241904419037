<template>
  <div class="mt-2">
    <b-overlay :show="loading" rounded="sm" no-fade>
      <b-card>
        <b-row>
          <b-col xl="12" md="12" sm="12" cols="12">
            <b-row>
              <b-col cols="6" lg="6" xl="6" md="12" sm="12">
                <b-form-group class="mb-40">
                  <b-input-group>
                    <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>
                    <b-input-group-append>
                      <b-button @click="filter = ''" variant="warning">Clear</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!-- <b-col cols="6" lg="6" xl="6" md="12" sm="12">
                  <b-button
                  variant="primary"
                  class="mr-1 mb-2 float-right"
                  :to="{ name: 'settings-createWorkshop' }"
                >
                  New Contract
                </b-button>
                </b-col> -->
            </b-row>

            <b-table
              striped
              hover
              ref="refTimeAndEffortsTable"
              class="position-relative"
              :items="timeAndEffortsData"
              :filter="filter"
              filter-debounce="250"
              @filtered="onFiltered"
              selectable
              select-mode="single"
              responsive
              :small="true"
              head-variant="dark"
              :fields="inHeaders"
              show-empty
              empty-text="No matching records found"
              @row-selected="onRowSelected"
              :current-page="currentPage"
              :per-page="perPage"
            >
              <template #cell(classRoom)="data">
                <span class="font-weight-bold">
                  {{ data.item.classRoom ? data.item.classRoom : 'N/A' }}
                </span>
              </template>
              <template #cell(app22)="data">
                <span class="font-weight-bold">
                  {{ data.item.app22 ? data.item.app22 : 'N/A' }}
                </span>
              </template>
              <template #cell(may22)="data">
                <span class="font-weight-bold">
                  {{ data.item.may22 ? data.item.may22 : 'N/A' }}
                </span>
              </template>
              <template #cell(jun22)="data">
                <span class="font-weight-bold">
                  {{ data.item.jun22 ? data.item.jun22 : 'N/A' }}
                </span>
              </template>
              <template #cell(jul22)="data">
                <span class="font-weight-bold">
                  {{ data.item.jul22 ? data.item.jul22 : 'N/A' }}
                </span>
              </template>
              <template #cell(aug22)="data">
                <span class="font-weight-bold">
                  {{ data.item.aug22 ? data.item.aug22 : 'N/A' }}
                </span>
              </template>
              <template #cell(sep22)="data">
                <span class="font-weight-bold">
                  {{ data.item.sep22 ? data.item.sep22 : 'N/A' }}
                </span>
              </template>
              <template #cell(oct22)="data">
                <span class="font-weight-bold">
                  {{ data.item.oct22 ? data.item.oct22 : 'N/A' }}
                </span>
              </template>
              <template #cell(nov22)="data">
                <span class="font-weight-bold">
                  {{ data.item.nov22 ? data.item.nov22 : 'N/A' }}
                </span>
              </template>
              <template #cell(dec22)="data">
                <span class="font-weight-bold">
                  {{ data.item.dec22 ? data.item.dec22 : 'N/A' }}
                </span>
              </template>
              <template #cell(jan23)="data">
                <span class="font-weight-bold">
                  {{ data.item.jan22 ? data.item.jan22 : 'N/A' }}
                </span>
              </template>
              <template #cell(feb23)="data">
                <span class="font-weight-bold">
                  {{ data.item.feb22 ? data.item.feb22 : 'N/A' }}
                </span>
              </template>
              <template #cell(marc23)="data">
                <span class="font-weight-bold">
                  {{ data.item.marc23 ? data.item.marc23 : 'N/A' }}
                </span>
              </template>
              <template #cell(grandTotal)="data">
                <span class="font-weight-bold">
                  {{ data.item.grandTotal ? data.item.grandTotal : 'N/A' }}
                </span>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                  <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                  </template>
                  <b-dropdown-item
                    :to="{
                      name: 'timeEffort-edit',
                      params: { id: data.item.id },
                    }"
                  >
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">Details </span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="deleteTimeEffort(data.item.id)">
                    <feather-icon icon="DeleteIcon" />
                    <span class="align-middle ml-50">Delete TimeEffort</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>

            <div class="mx-2 mb-2">
              <b-row>
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                </b-col>

                <!-- Pagination -->
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
// import { BFormFile } from "bootstrap-vue";
import { BImg, BOverlay, BCard, BFormGroup, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem } from 'bootstrap-vue';
import axiosIns from '@/libs/axios';
import store from '@/store';
import router from '@/router';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from '../../settingsStoreModule';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import moment from 'moment';
import _ from 'lodash';
import vSelect from 'vue-select';

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BFormGroup,
    moment,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  mounted() {
    // this.getItems();
    this.getTimeEfforts();
  },
  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'cpats';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    return { selected: ['Admin', 'User'] };
  },
  data() {
    return {
      file: null,
      fields: [
        {
          key: 'questions',
          label: 'questions',
          thStyle: { width: '80%' },
        },
        {
          key: 'answer',
          label: 'answer',
          thStyle: { width: '20%' },
        },
      ],
      excelJson: {},
      edit: null,
      newitem: '',
      filter: null,
      filterOn: [],
      perPage: 10,
      loading: false,
      items: [],
      where: router.currentRoute.name,
      currentPage: 1,
      pageOptions: [5, 10, 15, 20],
      totalRows: 0,
      timeAndEffortsData: [],
      prevItem: null,
      inHeaders: ['classroom', 'app-22', 'may-22', 'jun-22', 'jul-22', 'aug-22', 'sep-22', 'oct-22', 'nov-22', 'dec-22', 'jan-23', 'feb-23', 'mar-23', 'grandTotal'],
      tempStatusOptions: [{ label: 'Draft', value: 'A' }],
    };
  },
  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  methods: {
    onRowSelected(item) {
      router.push({ name: 'contract-edit', params: { id: item[0].id } });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getTimeEfforts() {
      this.loading = false;
    },
    deleteTimeEffort(id) {
      const self = this;
      this.$swal({
        title: 'Are you sure you want to delete the time & effort?',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
        }
      });
    },
    renameKey(obj, oldKey, newKey) {
      obj[newKey] = obj[oldKey];
      delete obj[oldKey];
    },
    objectify(key, value) {
      return {
        [key]: value,
      };
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
